<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <select-language :selected-language="addNewEvent" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Name"
              >
                <b-form-group
                  label="Title"
                  label-for="name"
                >
                  <b-form-input
                    id="Title"
                    v-model="addNewEvent.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Excerpt"
              >
                <b-form-group
                  label="Excerpt"
                  label-for="Excerpt"
                >
                  <b-form-textarea
                    id="Excerpt"
                    v-model="addNewEvent.exerpt"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Excerpt"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Descripption"
                label-for="blog-content"
                class="mb-2"
              >
                <quill-editor
                  id="blog-content"
                  v-model="addNewEvent.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Name"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <h5>Date & TIme</h5>
                  <flat-pickr
                    v-model="addNewEvent.date"
                    class="form-control"
                    :config="{dateFormat: 'Y-m-d'}"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
              >
                <b-form-group
                  label="Organizers"
                  label-for="organizers"
                >
                  <b-form-textarea
                    id="organizers"
                    v-model="addNewEvent.organization"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Organizers"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <VueFileAgent
                ref="vueFileAgent"
                v-model="fileRecords"
                :theme="'grid'"
                :multiple="true"
                :deletable="true"
                :meta="true"
                :accept="'image/*'"
                :max-size="'10MB'"
                :max-files="14"
                :help-text="'Choose event images'"
                :error-text="{
                  type: 'Invalid file type. Only images or zip Allowed',
                  size: 'Files should not exceed 10MB in size',
                }"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDelete($event)"
                @delete="fileDeleted($event)"
              />
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block">
                      <!-- :state="Boolean(featuredImage.image)" -->
                      <b-form-file
                        v-model="featuredImage.image"
                        accept=".jpg, .png"
                        placeholder="Choose file"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click="addEvent"
              >
                Add
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable no-restricted-globals */
import {
  onUnmounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { quillEditor } from 'vue-quill-editor'
import { required } from '@validations'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import Vue from 'vue'
import VueFileAgent from 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'
import store from '@/store'
import events from '../events'
import eventsModule from '../eventsModule'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueFileAgent)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    flatPickr,
    selectLanguage,
  },
  data() {
    return {
      required,
      snowOption: {
        theme: 'snow',
      },
      addNewEvent: {
        title: '',
        exerpt: '',
        image: '',
        description: '',
        date: '',
        organization: '',
        file: '',
        language_id: '',
      },
      featuredImage: {
        image: null,
        url: null,
      },
      fileRecords: [],
      fileRecordsForUpload: [],
      preview: '',
      image: '',
      validationErrors: {},
      loader: false,
    }
  },
  mounted() {
  },
  setup() {
    const EVENTS_DATA_MODULE = 'eventsModule'
    // const stor = useStore

    // Register module
    if (!store.hasModule(EVENTS_DATA_MODULE)) store.registerModule(EVENTS_DATA_MODULE, eventsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENTS_DATA_MODULE)) store.unregisterModule(EVENTS_DATA_MODULE)
    })

    const {
      addingProduct, eventsForm,
    } = events()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const { formData, setFormData } = handleFormData()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      addingProduct,
      eventsForm,
      formData,
      setFormData,
    }
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.addNewEvent.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeFile(e) {
      this.addNewEvent.file = e.target.files
      // eslint-disable-next-line no-restricted-syntax
    },
    addEvent() {
      this.$refs.infoRules.validate().then(success => {
        if (success) {
          this.loader = true
          this.setFormData(this.addNewEvent)
          this.fileRecords.map(files => this.formData.append('file[]', files.file))
          axios.post('add/event', this.formData).then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Added Successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/events/list')
            }
          }).catch(err => {
            Object.assign(this.validationErrors, err.response.data.errors)
          }).finally(() => {
            this.loader = false
          })
        }
      })
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
